// Contains helper functions for matrix client entities

import filter from "lodash/filter";
import transform from "lodash/transform";
import { DateTime } from "luxon";

import { eventTypes, memberRoles, membershipStates } from "../constants";
import { decodeMatrixIdToEmail } from "./chat-tools";
import { getRoomPrettyName, getUserPrettyName } from "./displayUtils";

export const getEventAuthorName = (mEvent) => {
  return mEvent.event.content.displayname;
};

export const getUserHandle = (userId) => {
  return userId.split(":")[0];
};

// TODO: Should ultimately store DM rooms / room ids in a separate place in
// useRoomStore or save an extra key on each room in the store isDMRoom
// TODO: check why https://github.com/matrix-org/matrix-js-sdk/issues/720 did
// not work - maybe because we were checking room.getMembers() instead of
// room.currentState.getMembers()
export const isDMRoom = ({ room }) => {
  const isRoomOfTwo = room.getMembers().length === 2;

  return isRoomOfTwo && getMDirects(room.client).includes(room.roomId);
};

export const getDMRoomMember = ({ room }) => {
  if (!isDMRoom({ room })) {
    return;
  }
  return room.client.getUser(room.summaryHeroes[0]);

  // TODO / observation: if user sets the name to a dm room when creating it,
  // summaryHeroes doesn't get set. that causes an error.
  // alternatively this member can be determined by getting the
  // room.current_state.members and finding the one that has
  // member.user_id !== room.myUserID
};

export const getRoomAlias = (room) => {
  // room.getCanonicalAlias()?.split(":")[0]
  // for DM rooms we'll have the displayName as title or if chat partner is deactivated
  // title will the room.name set by default with userId decoded
  // otherwise we can expect the room name to be normal or a custom organisation
  // room name
  let roomName = room.name;
  if (isDMRoom({ room })) {
    const member = getDMRoomMember({ room });
    const isDeactivated = member.displayName === member.userId;
    if (isDeactivated) {
      roomName = room.name.replace(
        member.userId,
        `with ${decodeMatrixIdToEmail(member.userId)}`
      );
    } else {
      return getUserPrettyName(roomName);
    }
  }
  return getRoomPrettyName(roomName);
};

export const getRoomTopic = (room) => {
  return room.currentState.getStateEvents("m.room.topic")[0]?.getContent()
    .topic;
};

export const isEventSender = (userId, mEvent) => {
  return (
    (mEvent?.event?.type === eventTypes.room.MESSAGE ||
      mEvent?.event?.type === eventTypes.room.TOPIC ||
      mEvent?.event?.type === eventTypes.room.MEMBER) &&
    mEvent?.sender?.userId === userId
  );
};

export const isEventTarget = (userId, mEvent) => {
  return (
    mEvent?.event?.type === eventTypes.room.MEMBER &&
    mEvent?.target?.userId === userId
  );
};

export const checkIfCanChangeRoomAvatar = ({ room }) => {
  const userId = room.client.getUserId();
  const { currentState } = room;
  return currentState.maySendStateEvent("m.room.avatar", userId);
};

export const checkIfCanChangeRoomName = ({ room }) => {
  const userId = room.client.getUserId();
  const { currentState } = room;

  return currentState.maySendStateEvent("m.room.name", userId);
};

export const checkIfCanChangeRoomTopic = ({ room }) => {
  const userId = room.client.getUserId();
  const { currentState } = room;
  return currentState.maySendStateEvent("m.room.topic", userId);
};

export const getRoomUnreadMeta = ({ room }) => {
  const mEvents = room.getLiveTimeline().getEvents();
  const readUpToEventId = room.getEventReadUpTo(room.client.getUserId());

  const unreadEvents = mEvents
    .slice(
      mEvents.findIndex((mEvent) => mEvent.getId() === readUpToEventId) + 1
    )
    .filter(
      (mEvent) =>
        !isUserInfoUpdateEvent(mEvent) &&
        mEvent.event.type !== "m.room.attachments"
    );

  // original and easier implementation was:
  // unreadEvents.findLastIndex((mEvent) => mEvent.getSender() === room.client.getUserId())
  // but it's not supported in node < 18 and some older versions of browsers, so
  // for now use this implementation.
  // reverse is a destructive method, so we need to create a new array
  const firstReadEventIndexInReverse = [...unreadEvents]
    .reverse()
    .findIndex((mEvent) => mEvent.getSender() === room.client.getUserId());

  const lastReadEventIndex =
    firstReadEventIndexInReverse === -1
      ? firstReadEventIndexInReverse
      : unreadEvents.length - 1 - firstReadEventIndexInReverse;

  return {
    firstUnread: unreadEvents[lastReadEventIndex + 1],
    unreadEvents,
    total: Math.max(0, unreadEvents.length - lastReadEventIndex - 1),
  };
};

export function getUsernameOfRoomMember(member) {
  return getUserPrettyName(member.name || member.userId);
}

export function getUsername({ userId, client }) {
  const user = client.getUser(userId);
  if (user === null) {
    return userId;
  }
  let username =
    user.displayName === user.rawDisplayName
      ? user.displayName
      : user.rawDisplayName;

  if (typeof username === "undefined") {
    username = getUserHandle(userId);
  }

  return getUserPrettyName(username);
}

export function getPowerRole(powerLevel) {
  if (powerLevel > 100) {
    return memberRoles.OWNER;
  }
  if (powerLevel === 100) {
    return memberRoles.ADMIN;
  }
  if (powerLevel === 50) {
    return memberRoles.MODERATOR;
  }
  return memberRoles.MEMBER;
}

export function getPowerLevelByRole(role) {
  switch (role) {
    case memberRoles.OWNER:
      return 200;
    case memberRoles.ADMIN:
      return 100;
    case memberRoles.MODERATOR:
      return 50;
    case memberRoles.MEMBER:
      return 0;
    default:
      return 0;
  }
}

export const sortMembersByAtoZ = (m1, m2) => {
  const aName = m1.name;
  const bName = m2.name;

  if (aName.toLowerCase() < bName.toLowerCase()) {
    return -1;
  }
  if (aName.toLowerCase() > bName.toLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortMemberByPowerLevel = (m1, m2) => {
  const pl1 = m1.powerLevel;
  const pl2 = m2.powerLevel;

  if (pl1 > pl2) {
    return -1;
  }
  if (pl1 < pl2) {
    return 1;
  }
  return 0;
};

export const isSupportedEvent = ({ mEvent, client }) =>
  // Only include supported message types
  Object.values(eventTypes.room).includes(mEvent.event.type) &&
  (!isMembershipEvent({ mEvent }) || isSupportedMembershipEvent({ mEvent }));

// Will filter an array of events to only include displayable events
export const getSupportedEvents = ({ mEvents, client }) => {
  return filter(mEvents, (mEvent) => isSupportedEvent({ mEvent, client }));
};

export const getEventTime = ({
  mEvent,
  format = "T",
  dateIfNotTodayFormat,
}) => {
  const mEventDateTime = DateTime.fromMillis(mEvent.event.origin_server_ts);
  const mEventDateTimeFormat =
    dateIfNotTodayFormat && !mEventDateTime.hasSame(DateTime.local(), "day")
      ? dateIfNotTodayFormat
      : format;

  /* https://moment.github.io/luxon/#/formatting?id=table-of-tokens */
  return mEventDateTime.toFormat(mEventDateTimeFormat);
};

export const isMembershipEvent = ({ mEvent }) => {
  return mEvent?.event.type === eventTypes.room.MEMBER;
};

export const isTopicChangeEvent = ({ mEvent }) => {
  return mEvent?.event.type === eventTypes.room.TOPIC;
};

export const isSupportedMembershipEvent = ({ mEvent }) => {
  return (
    isMembershipEvent({ mEvent }) &&
    Object.keys(membershipStates).includes(mEvent.event.content.membership)
  );
};

export const isNotOwnMembershipEvent = ({ mEvent, client }) => {
  return (
    isSupportedMembershipEvent({ mEvent }) &&
    mEvent.event.content.displayname !== client.getUserIdLocalpart()
  );
};

export function getFirstLinkedTimeline(timeline) {
  return timeline.prevTimeline
    ? getFirstLinkedTimeline(timeline.prevTimeline)
    : timeline;
}

export function areAllEventsLoaded(timeline) {
  return (
    timeline.getEvents()[0].event.type === "m.room.create" ||
    getFirstLinkedTimeline(timeline).getPaginationToken("b") === null
  );
}

export function eventsFillingTheScreen(timeline) {
  if (areAllEventsLoaded(timeline)) {
    return true;
  }
  const messageEvents = timeline
    .getEvents()
    .filter((event) => event.getType() === "m.room.message");
  const messageCount = messageEvents.length;
  return messageCount * 70 > window.innerHeight;
}

export function iterateLinkedTimelines(timeline, callback, first = true) {
  timeline = first ? getFirstLinkedTimeline(timeline) : timeline;
  callback(timeline);
  if (!timeline.nextTimeline) {
    return;
  }
  iterateLinkedTimelines(timeline.nextTimeline, callback, false);
}

export function getFileDownloadURL(matrixMediaRepositoryURL, baseUrl) {
  return matrixMediaRepositoryURL
    ? `${baseUrl}/_matrix/media/v3/download${matrixMediaRepositoryURL.slice(5)}`
    : "";
}

export async function kickAllUsersBesidesAdminFromRoom({
  members,
  adminId,
  room,
  client,
}) {
  const roomAlias = getRoomAlias(room);
  const userIds = members
    .map(({ userId }) => userId)
    .filter((userId) => userId !== adminId);
  const kickPromises = userIds.map((userId) =>
    client.kick(room.roomId, userId, `The group "${roomAlias}" was deleted.`)
  );

  try {
    await Promise.all(kickPromises);
    console.log("All users have been kicked from the room.");
  } catch (error) {
    console.log("Error kicking users:", error);
  }
}

export const getMDirects = (client) => {
  const mDirects = client.getAccountData("m.direct")?.getContent();
  if (typeof mDirects === "undefined") {
    return [];
  }

  return transform(
    mDirects,
    (result, direct) => {
      result.push(...direct);
    },
    []
  );
};

export const isUserInfoUpdateEvent = (mEvent) => {
  const currentEventDisplayName = mEvent.event.content?.displayname;
  const prevEventDisplayName = mEvent.event.unsigned?.prev_content?.displayname;

  if (!currentEventDisplayName || !prevEventDisplayName) {
    return false;
  } else {
    return currentEventDisplayName !== prevEventDisplayName;
  }
};
